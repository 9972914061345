/* eslint no-console:0 */

// This file is automatically compiled by Webpack, along with any other files present in this directory. You're encouraged to place your actual application logic in a relevant structure within app/javascript and only use these pack files to reference that code so it'll be compiled.

// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate layout file, like app/views/layouts/application.html.erb

console.log("Application.js")

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")
require("trix")
require("@rails/actiontext")

window.jQuery = $;
window.$ = $;

import 'bootstrap'
import '@fortawesome/fontawesome-pro/js/all.js'
import 'synapse_ui/app/javascript/packs/application'
import 'stylesheets/application'

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
// const context = require.context("./controllers", true, /\.js$/)
// application.load(definitionsFromContext(context))

// See: https://github.com/projectblacklight/blacklight/wiki/Using-Webpacker-to-compile-javascript-assets
const gem_context = require.context('synapse_ui/app/javascript/packs/controllers', true, /\.js$/)
application.load(definitionsFromContext(gem_context))

// Log the controllers that are available...
// console.log("controller definitions:", definitionsFromContext(context))
console.log("controller definitions:", definitionsFromContext(gem_context))

// if (navigator.serviceWorker) {
//   console.log("Attempting to register service worker")
//   navigator.serviceWorker.register('/service-worker.js', { scope: './' })
//     .then(function(reg) {
//       console.log('[Companion]', 'Service worker registered!');
//       console.log(reg);
//     });
// }

$(document).on("turbolinks:load", function(){
  console.log(event);
})

// Show spinner during Turbolinks loads
$(document).on('turbolinks:load', function() {
  // hide spinner when a page is loaded
  $(".spinner").hide();
  $('[data-toggle="tooltip"]').tooltip();
});
$(document).on('turbolinks:click', function() {
  // show spinner when a Turbolinks link is clicked
  $(".spinner").show();
});

// Show spinner during AJAX requests
$(document).on('turbolinks:load', function() {
  // hide spinner to start
  $(".spinner").hide();
  // show spinner on AJAX start
  $(document).not(".live_search").ajaxStart(function(){
    $(".spinner").show();
  });
  // hide spinner on AJAX stop
  $(document).not(".live_search").ajaxStop(function(){
    $(".spinner").hide();
  });
});

// Enable chosen select
$(document).on('turbolinks:load', function() {
  $('.chosen-select').chosen({
    allow_single_deselect: true,
    no_results_text: 'No results matched',
    width: '100%'
  });
});

// Live search w/ AJAX
$(document).on('turbolinks:load', function() {
  $(".live_search input").keyup(function() {
    $.get($(".live_search").attr("action"), $(".live_search").serialize(), null, "script");
    return false;
  });
});

// Rotate button icons on click
$(document).on('turbolinks:load', function() {
  $(".rotate-icon").click(function(){
    $(this).toggleClass("down");
  });
});
